"use strict";

import Vue from 'vue';
import axios from "axios";
import "./api";
import {store} from "@/store";
import {StatusCodeHelper} from "@/helpers/StatusCodeHelper";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
 axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '/api/';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);
const StatusCodeAlert = new StatusCodeHelper();
_axios.defaults.headers.common['Content-Type']="application/x-www-form-urlencoded";
_axios.interceptors.request.use(
  function(config) {
      document.body.style.cursor='wait'
      if(config.url && config.url.substr(0,1) == "/"){
        config.baseURL = "";
      }
      // Do something before request is sent
      if(localStorage.hasOwnProperty("accessToken")){
          //@ts-ignore
          config.headers['Authorization'] = 'Bearer '+localStorage.getItem("accessToken");
          //@ts-ignore
          config.headers['Tenant-Id'] = sessionStorage.getItem("tenantId");//'f4903e5d-d528-4463-aa3e-9ac717f2ee53';//store.state.tenant_id;
      }else{
          //@ts-ignore
          delete config.headers['Authorization'];
      }
      return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);


// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    document.body.style.cursor='default'
    // Do something with response data
    return response.data;
  },
  function(error) {
    document.body.style.cursor='default'
    StatusCodeAlert.error(error);
    return Promise.reject(error);
  }
);

export function AxiosPlugin<AxiosPlugOptions>(Vue: any, options?: AxiosPluginOptions): void {
  // do stuff with options
  Vue.prototype.$http = _axios;
}
export class AxiosPluginOptions {
  // add stuff
}
