import {TakipAcStatusEnum} from "./parser/TakipAcStatusEnum";
import app from "@/main";
import {MtsXmlParser} from "./MtsXmlParser";
import TakipAcInjectable from "./TakipAcInjectable";
import {Mixins} from "./Mixins";
import {MtsTakipTalebiOlusturCevap, MtsTakipTalebiOlusturTalep} from "../uyap/MtsTakipTalebiOlustur";
import {MtsTakipTalebiIndirTalep} from "../uyap/MtsTakipTalebiIndir";
import {MtsDavaAcilisTalep} from "../uyap/MtsDavaAcilis";
import {saveAs} from "file-saver";
import {TamamlanmayanDosyalarRow} from "../uyap/TamamlanmayanDosyalar";
import Args from "@/plugins/uyap-plugin/takip_ac/parser/Args";

export default class MtsTakipAc extends TakipAcInjectable {
    parsedFile!: MtsXmlParser;
    tevziBilgileri !: MtsTakipTalebiOlusturCevap;
    toplamTakibeEsasMiktar: number = 1;
    mtsHarcBilgileri = {
        selectedTarafHashKeyList: ["kurum_0"],
        selectedTarafHashList: []
    }
    dosyaBilgileri!: TamamlanmayanDosyalarRow;

    static fromJson(jsonData: any): MtsTakipAc {
        return Args.staticFromJson(jsonData,new MtsTakipAc());
    }

    //Yetkili Kurum secmesi gerekiyor.

    async runQuery(): Promise<void> {
        try {
            this.setStatus(TakipAcStatusEnum.QUERY_PENDING);
            for (const taraf of this.parsedFile.tarafList) {
                try {
                    console.log("MTS TARAF", taraf);
                    console.log("MTS parsedFile.formData", this.parsedFile);
                    await taraf.runQuery(this.parsedFile.formData.alacakliTaraf);
                } catch (e) {
                    this.setStatus(TakipAcStatusEnum.QUERY_FAIL);
                    console.log("tarafList HATA", e);
                }
            }
            await this.runQueryForHarcList();
            this.setStatus(TakipAcStatusEnum.QUERY_SUCCESS);
            return Promise.resolve();
        } catch (e) {
            this.setStatus(TakipAcStatusEnum.QUERY_FAIL);
            console.log("ERR RUNQUERY");
            console.log(e);
            return Promise.reject(e);
        }
    }

    async runQueryForHarcList(): Promise<any> {
        try {
            let harcBilgileri = await app.$uyap.MtsHarcList().run(this.toUyapJson());
            // @ts-ignore
            this.mtsHarcBilgileri.selectedTarafHashList = harcBilgileri.data;
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    async tevziAl(): Promise<any> {
        try {
            this.setStatus(TakipAcStatusEnum.PREPARE_PROCESSING);
            await this.mukerrerDosyaKontrol(this.parsedFile.hash, "xml");
            this.addMessage('info', 'Tevzi alınıyor!')
            this.tevziBilgileri = await app.$uyap.MtsTakipTalebiOlustur().run(this.tevziToUyapJson());
            this.addMessage('info', 'Tevzi alındı.!');
            this.addMessage('info', 'Takip talebi hazırlanıyor!');
            let takipTalebiCevap = await app.$uyap.MtsTakipTalebiIndir(this.tevziBilgileri.dosyaId).run(new MtsTakipTalebiIndirTalep())
            this.imzasizUdf = takipTalebiCevap.file;
            this.addMessage('success', 'Tevzi alma işlemi başarılı!');
            this.setStatus(TakipAcStatusEnum.PREPARE_SUCCESS);
            return Promise.resolve();
        } catch (e) {
            this.setStatus(TakipAcStatusEnum.PREPARE_FAILED);
            this.addMessage('error', 'Tevzi alma işleminde hata oluştu!' + e.message);
            return Promise.reject(e);
        }
    }

    async takipTalebiIndir() {
        saveAs(this.imzasizUdf, "takiptalebi.udf");

    }

    async takipBaslat(): Promise<void> {
        try {
            this.setStatus(TakipAcStatusEnum.OPEN_PROCESSING);
            await this.vekaletKontrol();
            await this.takipAcilisEvraklariniGonder(this.tevziBilgileri.dosyaId);

            if (this.toplamTakibeEsasMiktar == 0 && this.parsedFile.formData.odemeYapilmasin === true) {
                //harc muafli takip acilmissa odeme yapmiyor...
                await this.setTakipBilgileriFromTakipAcilisEvraklariResponse();
            } else if (this.toplamTakibeEsasMiktar == 0) {
                //harc muafli takip acilmissa odeme yapmiyor...
                await this.takipAcilisEvraklariniGonder(this.tevziBilgileri.dosyaId);
                await this.setTakipBilgileriFromTakipAcilisEvraklariResponse();
            } else if (this.parsedFile.formData.odemeYapilmasin === true) {
                await this.takipAcilisEvraklariniGonder(this.tevziBilgileri.dosyaId);
                this.takipBilgileri.dosyaEsasNo = "Dosya tevzi alındı. " + this.takipAcilisEvraklariResponse.message;
                this.takipBilgileri.icraDairesi = "Merkezi Takip Sistemi";
                this.addMessage("success", "Takibiniz ödeme yapılmasın seçilerek açılmıştır. Ödemenizi UYAP üzerinden yapabilirsiniz.");
                this.setStatus(TakipAcStatusEnum.OPEN_SUCCESS);
            } else {
                await this.takipAcilisEvraklariniGonder(this.tevziBilgileri.dosyaId);
                await this.odemeYap();
            }
            return this.setTakipBilgileriToIcraPro();
        } catch (e) {
            this.addMessage('error', 'HATA - ' + e.message);
            this.setStatus(TakipAcStatusEnum.OPEN_FAIL);
        }
    }

    async odemeYap(): Promise<void> {
        try {
            let takipBilgileri = await app.$uyap.MtsDavaAcilis().run(this.davaAcilisToUyapJson());
            this.takipBilgileri.dosyaEsasNo = takipBilgileri.dosyaNo;
            this.takipBilgileri.icraDairesi = takipBilgileri.birimAdi;
            this.addMessage('success', 'Takip açıldı.');
            this.setStatus(TakipAcStatusEnum.OPEN_SUCCESS);
            return Promise.resolve();
        } catch (e) {
            this.addMessage('error', 'Ödeme hatası - ' + e.message);
            return Promise.reject(e);
        }
    }

    vekaletKontrol(): Promise<Blob> {
        if (!this.getVekaletBlob() || this.getVekaletBlob() == null) {
            let message = "Vekalet eklenmemiş lütfen vekalet ekleyiniz!";
            this.addMessage('error', message);
            return Promise.reject({message: message})
        }
        return Promise.resolve(this.getVekaletBlob());
    }

    davaAcilisToUyapJson(): MtsDavaAcilisTalep {
        let data: any = this.tevziToUyapJson();
        data.dosyaId = this.tevziBilgileri.dosyaId;
        return data;
    }

    tevziToUyapJson(): MtsTakipTalebiOlusturTalep {
        let {
            dosyaAciklama_48_4,
            mahiyetList,
            dosyaKriterList,
            terekemi,
            selectedIl,
            selectedBirim
        }: any = this.parsedFile.mtsDosyaBilgileri;
        let tarafList = this.toUyapTarafList();
        terekemi = Mixins.booleanToXmlStrings(terekemi);

        return {
            MTS: 1,
            MTSDosyaBilgileri: JSON.stringify({
                dosyaAciklama_48_4,
                mahiyetList,
                dosyaKriterList,
                terekemi,
                selectedIl,
                selectedBirim,
            }),
            tarafList: JSON.stringify(tarafList),
            ilamsizList: JSON.stringify(this.parsedFile.ilamsizList.map((i) => i.toUyapJson())),
            MTSHarcBilgileri: JSON.stringify(this.mtsHarcBilgileri)
        }
    }

    toUyapTarafList() {
        //let tarafList = this.tarafList.map((i) => i.toUyapJson());
        let kisiList = this.parsedFile.tarafList.filter(taraf => taraf.isKisi() === true);
        let kurumList = this.parsedFile.tarafList.filter(taraf => taraf.isKurum() === true);
        return {
            kisiList: kisiList.map((taraf, key) => taraf.toUyapJson(key)),
            kurumList: kurumList.map((taraf, key) => taraf.toUyapJson(key))
        };
    }

    toUyapJson() {
        let result = {
            ilamsizList: JSON.stringify(this.parsedFile.ilamsizList.map((i) => i.toUyapJson())),
            tarafList: JSON.stringify(this.toUyapTarafList())
        };
        console.log(result);
        console.log("MTS toUyapJson");
        return result;
    }

    toUyapJsonTest() {
        return {
            uyapJson: this.parsedFile.ilamsizList.map((i) => i.toUyapJson()),
            ilamsizList: this.parsedFile.ilamsizList
        };
    }


}