import TebligatController from "../../../haciz/TebligatController";
import {TebligatEvrakTypeEnum} from "../../../uyap/enum/TebligatEvrakTypeEnum";
import {AbstractTaskHandler} from "./AbstractTaskHandler";
import {ITask} from "../GorevYoneticisiTypes";
import {TopluHacizHazirlaTaskCevap} from "./TopluHacizHazirlaTaskHandler";
import {TopluHacizGonderTaskResponse} from "./TopluHacizGonderTaskHandler";
import {StatusEnum} from "../../../enum/StatusEnum";
import {TaskDurum} from "../../../enum/TaskDurum";
import JSZip from "jszip";
import app from "@/main";
import {IKisiTumDVO} from "@/plugins/uyap-plugin/uyap/CommonInterfaces";

export class TopluTebligatGonderTaskHandler extends AbstractTaskHandler {
    params: any[] = [];

    async handle(task: ITask): Promise<ITask> {
        let data: TopluHacizHazirlaTaskCevap = task.data;
        let dosya = await app.$uyap.DosyaBul().run(task);
        data.dosya = dosya;
        this.params = data.params.tebligatParams;
        let response: TopluHacizGonderTaskResponse = {
            dosyaEsasNo: data.dosya.dosyaNo,
            icraDairesi: data.dosya.birimAdi,
            message: "",
            evraklar: []
        };
        if (data.borclular.length > 0) {
            for (const borclu of data.borclular) {
                try {
                    let tebligatController = new TebligatController(borclu, data.dosya);
                    for (const param of this.params) {
                        switch (param.className) {
                            case TebligatEvrakTypeEnum.YUZUCDAVETIYESININ_TEBLIGE_CIKARTILMASI: {
                                tebligatController.yuz3DavetiyesininTebligeCikartilmasiEkle(borclu.tebligat.yuzucDavetiyesininTebligeCikartilmasiParams);
                                break;
                            }
                            case TebligatEvrakTypeEnum.BakiyeBorcMuhtirasininTebligeCikartilmasi: {
                                tebligatController.bakiyeBorcMuhtirasininTebligeCikartilmasiEkle(borclu.tebligat.bakiyeBorcMuhtirasininTebligeCikartilmasiParams);
                                break;
                            }
                            case TebligatEvrakTypeEnum.IcraOdemeEmrininTebligeCikartilmasi: {
                                tebligatController.icraOdemeEmrininTebligeCikartilmasiEkle(borclu.tebligat.icraOdemeEmrininTebligeCikartilmasiParams);
                                break;
                            }
                            case TebligatEvrakTypeEnum.KiymetTakdirininTebligeCikartilmasi: {
                                tebligatController.kiymetTakdirininTebligeCikartilmasiEkle(borclu.tebligat.kiymetTakdirininTebligeCikartilmasiParams);
                                break;
                            }
                            case TebligatEvrakTypeEnum.TaahuduKabulMuhtirasiTebligi: {
                                tebligatController.taahhuduKabulMuhtirasiTebligiEkle(borclu.tebligat.taahuduKabulMuhtirasiTebligiParams);
                                break;
                            }
                            case TebligatEvrakTypeEnum.TemlikBilgisininBildirilmesi: {
                                tebligatController.temlikbilgisininBildirilmesiEkle(borclu.tebligat.temlikBilgisininBildirilmesiParams);
                                break;
                            }
                            case TebligatEvrakTypeEnum.YenilemeEmrininTebligeCikartilmasi: {
                                tebligatController.yenilemeEmrininTebligeCikartilmasiEkle(borclu.tebligat.yenilemeEmrininTebligeCikartilmasiParams);
                                break;
                            }
                        }
                    }
                    await tebligatController.hacizEvrakHazirla();
                    await tebligatController.hacizEvrakImzala();
                    let evrakResult = await tebligatController.gonder();
                    let borcluKisi= borclu.borcluBilgileri as IKisiTumDVO;
                    response.evraklar.push({
                        message             : evrakResult.message,
                        status              : StatusEnum.success,
                        imzaliBlob          : tebligatController.getHacizEvrakImzali(),
                        imzasizBlob         : tebligatController.getHacizEvrakImzasiz(),
                        adSoyad             : borcluKisi.adi + " " + borcluKisi.soyadi,
                        tcKimlikNo          : borcluKisi.tcKimlikNo,
                        evrakTutari         : tebligatController.evrakTutar.toFixed(2),
                        params              : this.params
                    })
                } catch (e) {
                    let borcluKisi= borclu.borcluBilgileri as IKisiTumDVO;
                    response.evraklar.push({
                        message             : e.message,
                        status              : StatusEnum.error,
                        imzaliBlob          : null,
                        imzasizBlob         : null,
                        adSoyad             : borcluKisi.adi + " " + borcluKisi.soyadi,
                        tcKimlikNo          : borcluKisi.tcKimlikNo,
                        evrakTutari         : 0,
                        params              : this.params
                    });
                }
            }
            let zip = new JSZip();
            for (let evrak of response.evraklar) {
                if (evrak.imzaliBlob) {
                    // @ts-ignore
                    zip.file((evrak.adSoyad + "/" + evrak.imzaliBlob.name), evrak.imzaliBlob);
                }
            }
            let zipBlob = await zip.generateAsync({type: "blob"});
            let formData = new FormData();
            // @ts-ignore
            let name = task.birim_adi + ' ' + task.dosya_esas_no.replaceAll('/', '_') + ' Toplu Tebligat Talepleri_' + new Date().getTime() + '.zip';
            formData.append('name', name);
            formData.append('file', zipBlob);
            formData.append('task_id', (task.id as number).toString());
            await app.$http.post('/api/v1/task/file', formData);
        } else {
            response.message = "Dosyada borçlu bulunamadı.";
        }
        let errorExists = response.evraklar.filter(evrak => evrak.status == StatusEnum.error);
        task.status = TaskDurum.BITTI_BASARILI;
        if (errorExists.length == response.evraklar.length)
            task.status = TaskDurum.BITTI_HATALI;
        else if (errorExists.length > 0)
            task.status = TaskDurum.BITTI_EKSIK;
        task.response = response;
        task.description = errorExists.map(x => x.message).join(" , ");
        if (response.message != "")
            task.description = response.message;
        return task;
    }
}