export enum AlacakBelgeTuru {
    cek = 1,
    police,
    fatura,
    kiraKontrati,
    sozlesme,
    nafaka,
    iseIadeKarari,
    tuketiciHakemHeyetiKarari,
    mts,
    rehinBelgesi,
    senet,
    bono,
    isciAlacagi,
    tazminat,
    cocukTeslimi,
    kisiselIliski,
    birIsinYapilmasiYaDaYapilmamasi,
    irtifakVeyaIntifakHakkiKaldirilmasi,
    tahliyeTaahhudu,
    ipotekAkitTablosu,
    noterSenedi,
    diger,
    itirazinIptaliDavasi,
}

export enum AlacakBelgeTuruLabel {
    "Çek"=1,
    "Poliçe",
    "Fatura",
    "Kira Kontratı",
    "Sözleşme",
    "Nafaka",
    "İşe İade Kararı",
    "Tüketici Hakem Heyeti Kararı",
    "MTS",
    "Rehin Belgesi",
    "Senet",
    "Bono",
    "İşçi Alacağı",
    "Tazminat",
    "Çocuk Teslimi",
    "Kişisel İlişki",
    "Bir İşin Yapılması ya da Yapılmaması",
    "İrtifak Hakkının ya da Gemi Üzerindeki İntifa Hakkının Kaldırılması",
    "Tahliye Taahhüdü",
    "İpotek Akit Tablosu",
    "Noter Senedi",
    "Diğer",
    "İtirazın İptali Davası"
}