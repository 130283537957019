import {DosyaBorcluSSKCevap, SigortaDurumKod} from "./DosyaBorcluSSK";
import {DosyaBorcluEgmCevap} from "./DosyaBorcluEgm";
import {DosyaBorcluMernisCevap} from "./DosyaBorcluMernis";
import {SgkKamuCevap} from "./SgkKamu";
import {BorcluGibCevap} from "./BorcluGib";
import {DosyaBorcluBagkurCevap} from "./DosyaBorcluBagkur";
import {DosyaBorcluBankaBilgileriCevap} from "./DosyaBorcluBankaBilgileri";
import {BorcluDibCevap} from "./BorcluDib";
import {DosyaBorcluIsyeriSgkCevap} from "./DosyaBorcluIsyeriSgk";
import {BorcluTakbisSorgulamaCevap} from "./BorcluTakbisSorgulama";
import {BorcluSgkHacizCevap} from "./BorcluSgkHaciz";
import {BorcluIskiCevap} from "./BorcluIski";
import {BorcluIcraDosyasiCevap} from "./BorcluIcraDosyasi";
import {BorcluGsmCevap} from "./BorcluGsm";
import {BorcluPostaCekiCevap} from "./BorcluPostaCeki";
import app from "@/main";
import {DosyaBorcluHacizInterface,} from "../haciz/HacizParamsInterfaces";
import {DosyaBorcluTebligatInterface} from "../haciz/TebligatParamsInterface";
import {DigerTalepParamsInterface} from "../haciz/TopluDigerTalepParamsInterface";
import {BorcluTur, IBorcluBilgileri, IKisiTumDVO, IKurumDVO} from "./CommonInterfaces";
import {DosyaBorcluEgmMahrumiyetCevap} from "./DosyaBorcluEgmMahrumiyet";

export class DosyaBorclu {
    turu: BorcluTur;
    kisiKurumId: number;
    borcluBilgileri: IKisiTumDVO | IKurumDVO;
    sskAktif: boolean = false;
    ssk: DosyaBorcluSSKCevap | EmptyObject = new EmptyObject();
    egm: DosyaBorcluEgmCevap | EmptyObject = new EmptyObject();
    mernis: DosyaBorcluEgmMahrumiyetCevap | EmptyObject = new EmptyObject();
    mersis: DosyaBorcluMernisCevap | EmptyObject = new EmptyObject();
    sgkkamu: SgkKamuCevap | EmptyObject = new EmptyObject();
    gib: BorcluGibCevap | EmptyObject = new EmptyObject();
    sgkbagkur: DosyaBorcluBagkurCevap | EmptyObject = new EmptyObject();
    bankaBilgileri: DosyaBorcluBankaBilgileriCevap | EmptyObject = new EmptyObject();
    dib: BorcluDibCevap | EmptyObject = new EmptyObject();
    sgkIsyeri: DosyaBorcluIsyeriSgkCevap | EmptyObject = new EmptyObject();
    takbis: BorcluTakbisSorgulamaCevap | EmptyObject = new EmptyObject();
    sgkhaciz: BorcluSgkHacizCevap | EmptyObject = new EmptyObject();
    iski: BorcluIskiCevap | EmptyObject = new EmptyObject();
    icraDosyaKaydi: BorcluIcraDosyasiCevap | EmptyObject = new EmptyObject();
    // gsm bozuk
    gsm: BorcluGsmCevap = new BorcluGsmCevap();
    postaCeki: BorcluPostaCekiCevap | EmptyObject = new EmptyObject();
    haciz: DosyaBorcluHacizInterface = new DosyaBorcluHacizInterface();
    tebligat: DosyaBorcluTebligatInterface = new DosyaBorcluTebligatInterface();
    digerTalep: DigerTalepParamsInterface = new DigerTalepParamsInterface();
    isKisiBool: boolean = false;
    isKurumBool: boolean = false;

    constructor(borclu: IBorcluBilgileri) {
        this.turu = borclu.turu;
        this.kisiKurumId = borclu.kisiKurumId;
        if (this.turu == BorcluTur.KISI) {
            this.borcluBilgileri = borclu.kisiTumDVO as IKisiTumDVO;
            this.isKisiBool = true;
            this.isKurumBool = false;
        } else {
            this.borcluBilgileri = borclu.kurumDVO as IKurumDVO;
            this.isKisiBool = false;
            this.isKurumBool = true;
        }
    }

    isKurum(): boolean {
        return this.turu == BorcluTur.KURUM;
    }

    isKisi(): boolean {
        return this.turu == BorcluTur.KISI;
    }

    setBorcluErrorAllFields(message: string) {
        this.ssk.message = message;
        this.egm.message = message;
        this.mernis.message = message;
        this.mersis.message = message;
        this.sgkkamu.message = message;
        this.gib.message = message;
        this.sgkbagkur.message = message;
        this.bankaBilgileri.message = message;
        this.dib.message = message;
        this.sgkIsyeri.message = message;
        this.takbis.message = message;
        this.sgkhaciz.message = message;
        this.iski.message = message;
        this.icraDosyaKaydi.message = message;
        this.gsm.message = message;
        this.postaCeki.message = message;
    }

    async queryBorcluSsk(dosyaId: string): Promise<void> {
        try {
            this.ssk = await app.$uyap.DosyaBorcluSSK().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId
            });
            return Promise.resolve();
        } catch (e) {
            this.ssk.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryEgm(dosyaId: string): Promise<DosyaBorcluEgmCevap> {
        try {
            this.egm = await app.$uyap.DosyaBorcluEgm().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                pageIndex: 0
            });
            return Promise.resolve(this.egm as DosyaBorcluEgmCevap);
        } catch (e) {
            this.egm.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryEgmMahrumiyet(dosyaId: any): Promise<void> {
        try {
            await this.queryEgm(dosyaId);
            let egm = this.egm as DosyaBorcluEgmCevap;
            if (egm.sorguSonucu && egm.sorguSonucDVO) {
                for (let arac of egm.sorguSonucDVO.aracBilgileri.output) {
                    try {
                        arac.mahrumiyet = await app.$uyap.DosyaBorcluEgmMahrumiyet().run({
                            dosyaId: dosyaId,
                            kisiKurumId: this.kisiKurumId,
                            plaka: arac.plakaSifreli
                        })
                        console.log("ARAC MAHRUMIYET", arac.mahrumiyet);
                    } catch (e) {
                        // @ts-ignore
                        arac.mahrumiyet.message = e.message;
                    }
                }
            } else
                return Promise.reject("Kişiye ait araç kaydı yok")
            return Promise.resolve();
        } catch (e) {
            console.log("MAHRUMIYET ERR", e);
            return Promise.reject(e);
        }
    }

    async queryBorcluMernis(dosyaId: string): Promise<void> {
        try {
            this.mernis = await app.$uyap.DosyaBorcluMernis().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId
            });
            return Promise.resolve();
        } catch (e) {
            this.mernis.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluMersis(dosyaId: string): Promise<void> {
        try {
            this.mersis = await app.$uyap.DosyaBorcluMersis().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId
            });
            return Promise.resolve();
        } catch (e) {
            this.mersis.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluSgkKamu(dosyaId: string): Promise<void> {
        try {
            this.sgkkamu = await app.$uyap.SgkKamu().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId
            });
            return Promise.resolve();
        } catch (e) {
            this.sgkkamu.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluGib(dosyaId: string): Promise<void> {
        try {
            this.gib = await app.$uyap.BorcluGib().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId
            });
            return Promise.resolve();
        } catch (e) {
            this.gib.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluSgkBagkur(dosyaId: string): Promise<void> {
        try {
            this.sgkbagkur = await app.$uyap.DosyaBorcluBagkur().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId
            });
            return Promise.resolve();
        } catch (e) {
            this.sgkbagkur.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluDib(dosyaId: string): Promise<void> {
        try {
            this.dib = await app.$uyap.BorcluDib().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
            });
            return Promise.resolve();
        } catch (e) {
            this.dib.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluSgkIsyeri(dosyaId: string): Promise<void> {
        try {
            this.sgkIsyeri = await app.$uyap.DosyaBorcluIsyeriSgk().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId
            });
            return Promise.resolve();
        } catch (e) {
            this.sgkIsyeri.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluTakbis(dosyaId: string): Promise<void> {
        try {
            this.takbis = await app.$uyap.BorcluTakbisSorgulama().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
            });
            return Promise.resolve();
        } catch (e) {
            this.takbis.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluSgkHaciz(dosyaId: string): Promise<void> {
        try {
            this.sgkhaciz = await app.$uyap.BorcluSgkHaciz().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
            });
            return Promise.resolve();
        } catch (e) {
            this.sgkhaciz.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluIski(dosyaId: string): Promise<void> {
        try {
            this.iski = await app.$uyap.BorcluIski().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId
            });
            return Promise.resolve();
        } catch (e) {
            this.iski.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluIcraDosyaKaydi(dosyaId: string): Promise<void> {
        try {
            this.icraDosyaKaydi = await app.$uyap.BorcluIcraDosyasi().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
            });
            return Promise.resolve();
        } catch (e) {
            this.icraDosyaKaydi.message = e.message;
            return Promise.reject(e);
        }
    }

    // TODO: uyapda bozuk
    async queryBorcluGsm(dosyaId: string): Promise<void> {
        try {
            this.gsm = await app.$uyap.BorcluGsm().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: 1
            });
            return Promise.resolve();
        } catch (e) {
            this.gsm.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluPostaCeki(dosyaId: string): Promise<void> {
        try {
            this.postaCeki = await app.$uyap.BorcluPostaCeki().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId
            });
            return Promise.resolve();
        } catch (e) {
            this.postaCeki.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluBankaBilgileri(dosyaId: string): Promise<void> {
        try {
            this.bankaBilgileri = await app.$uyap.DosyaBorcluBankaBilgileri().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId
            });
            return Promise.resolve();
        } catch (e) {
            this.bankaBilgileri.message = e.message;
            return Promise.reject(e);
        }
    }

    async isSskDurumAktif(dosyaId: any): Promise<boolean> {
        await this.queryBorcluSsk(dosyaId);
        this.sskAktif = false;
        let ssk = this.ssk as DosyaBorcluSSKCevap;
        let donemYilAy = ssk.sorguSonucDVO?.sskBilgiDetay.sgkIsyeriDVO.sigortaliBilgi.donemYilAy;
        let durum = ssk.sorguSonucDVO?.sskBilgiDetay.sgkIsyeriDVO.sigortaliBilgi.durumKod;
        let aktifSayilabilecekDonem = new Date(app.$uyapHelper.getDateNow("/", "month -2", true)).getTime(); //2019/01
        let mevcutDosyaDonem = new Date(donemYilAy as string).getTime();
        if (durum == SigortaDurumKod.AKTIF) {
            if (mevcutDosyaDonem > aktifSayilabilecekDonem)
                this.sskAktif = true;
        }
        return this.sskAktif;
    }
}

class EmptyObject {
    message: string = "";
}