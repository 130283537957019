import app from "@/main";
import {EvrakGonderCevap} from "../uyap/EvrakGonder";
import {EvrakGonderTaskDataInterface} from "../store/modules/TaskDataInterface/EvrakGonderTaskDataInterface";

export async function evrakGonder(data: { dosyaId: number | string, birimAdi: string, dosyaNo: string, birimId: string, evraklar: Array<EvrakGonderTaskDataInterface> }): Promise<EvrakGonderCevap> {
    let odemeAlinacakEvrakListesi = ["CZM_VEKALETNAME", "HKM_VEKALETNAME_YETKI_BELGESI", "CZM_YETKI_BELGESI", "ICR_YTK_BLG", "AVK_DSP_VKLETYTK"];
    let odemeEkle = false;

    let evraklar = data.evraklar;
    let items = [];
    let anaEvrakSay = 1;
    let say = 1;
    let form = new FormData();
    let uzantiList = ["udf", "pdf", "jpg", "png", "jpeg", "tif", "tiff", "JPG", "JPEG", "PNG", "PDF", "TIF", "TIFF"]
    let defaultTurlist = ["udf", "pdf", "jpg", "png", "jpeg", "image/jpg", "image/jpeg", "tif", "tiff", "JPG", "JPEG", "PNG", "PDF", "TIF", "TIFF"]
    let turList = [];
    let dosyaId = data.dosyaId;
    let index = 0;
    for (const evrak of evraklar) {
        let evrakName = anaEvrakSay + "evrak.udf";
        if (evrak.dosyaData.name != "undefined") {
            evrakName = evrak.dosyaData.name;
        } else {
            let evrakType = evrak.dosyaData.type;
            uzantiList.forEach(function (value) {
                if (evrakType.indexOf(value) >= 0) {
                    evrakName = "dosya_vek_" + index + "_." + value;
                }
            })
        }

        const evrakTuru = evrak.seciliTur.turKodu;
        const evrakTurAciklama = evrak.seciliTur.aciklama;

        odemeEkle = (odemeAlinacakEvrakListesi.indexOf(evrakTuru) > -1);
        if (evrakTuru == "CZM_VEKALETNAME") {
            turList = defaultTurlist;
        } else {
            turList = ["udf"];
        }
        items.push({
            id: index + 1,//i 0 oldugu icin +1 = id 1 den basliyor
            evrakTuruOptionDVO: {
                tur: evrakTuru,
                id: evrakTuru,
                label: evrakTurAciklama,
                mandatory: false,
                max: 1,
                ekEvrakMax: 5,
                acceptTypeList: {
                    turList: turList
                }
            },
            tur: evrakTuru,
            turAciklama: evrakTurAciklama,
            mandatory: false,
            file: {},
            path: "C:\\fakepath\\" + evrakName,//evraklar[i].evrak.name,
            kullaniciEvrakAciklama: evrakTurAciklama,
            parentId: -1,
            isVekalet: false,
            fileId: anaEvrakSay
        });
        let dosyaBlob;
        if (evrak.dosyaData.dosya && evrak.dosyaData.file_res.id == 0)
            dosyaBlob = evrak.dosyaData.dosya;
        else
            dosyaBlob = await app.$uyapHelper.getBlobFromLink('/api/v1/file/download/' + evrak.dosyaData.file_res.file_path.replace('uploads/', ''));
        form.append("file_" + anaEvrakSay, dosyaBlob, evrakName);
        say++;
        for (const ekDosya of evrak.ekDosyalarData) {
            items.push({
                id: index + 1,
                evrakTuruOptionDVO: {
                    tur: evrakTuru,
                    id: evrakTuru,
                    label: evrakTurAciklama,
                    mandatory: false,
                    max: 1,
                    ekEvrakMax: 5,
                    acceptTypeList: {
                        turList: ["udf"]
                    }
                },
                tur: evrakTuru,
                turAciklama: evrakTurAciklama,
                mandatory: false,
                file: {},
                path: "C:\\fakepath\\" + ekDosya.name,
                kullaniciEvrakAciklama: "Ek " + say,
                parentId: anaEvrakSay,
                fileId: say
            });
            let ekDosyaBlob = await app.$uyapHelper.getBlobFromLink('/api/v1/file/download/' + ekDosya.file_res.file_path.replace('uploads/', ''));
            form.append("file_" + say, ekDosyaBlob, ekDosya.name);
            say++;
        }
        anaEvrakSay = say;
        index++;
    }
    form.append("items", JSON.stringify(items));
    form.append("dosyaId", dosyaId.toString());
    form.append('birimAdi', data.birimAdi);
    form.append('isDusurme', "false");
    form.append('evrakTipi', "undefined");
    form.append('dosyaNo', data.dosyaNo);
    form.append('birimId', data.birimId);
    if (odemeEkle) {
        form.append('harcMiktari', '25.6');
        form.append('pulMiktari', '40.3');
        form.append('vakifbankHesapBilgileri', '');
        form.append('vakifbankOdemeIstekBilgileri', '');
        form.append('smsSifre', '');
        form.append('odemeTipi', '2');
    }
    return await app.$uyap.EvrakGonder().run({
        formData: true,
        data: form
    });
}
