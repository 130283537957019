import HacizController from "../../../haciz/HacizController";
import {HacizEvrakTypeEnum} from "../../../uyap/enum/HacizEvrakTypeEnum";
import {AbstractTaskHandler} from "./AbstractTaskHandler";
import {ITask} from "../GorevYoneticisiTypes";
import {TopluHacizHazirlaTaskCevap} from "./TopluHacizHazirlaTaskHandler";
import {TaskDurum} from "../../../enum/TaskDurum";
import {StatusEnum} from "../../../enum/StatusEnum";
import JSZip from "jszip";
import app from "@/main";
import {DosyaBorcluEgmCevap} from "../../../uyap/DosyaBorcluEgm";
import {BorcluTakbisSorgulamaCevap} from "../../../uyap/BorcluTakbisSorgulama";
import {BorcluIcraDosyasiCevap} from "../../../uyap/BorcluIcraDosyasi";
import {BorcluPostaCekiCevap} from "../../../uyap/BorcluPostaCeki";
import {IKisiTumDVO} from "../../../uyap/CommonInterfaces";
import {BorcluSgkHacizCevap} from "../../../uyap/BorcluSgkHaciz";

export interface ResponseHacizEvrakInterface {
    message: string,
    status: StatusEnum,
    imzaliBlob: Blob | null,
    imzasizBlob: Blob | null,
    adSoyad: string,
    tcKimlikNo: string,
    evrakTutari: any,
    params: any[]
}

export interface TopluHacizGonderTaskResponse {
    dosyaEsasNo: string,
    icraDairesi: string,
    message: string,
    evraklar: ResponseHacizEvrakInterface[]
}

export class TopluHacizGonderTaskHandler extends AbstractTaskHandler {
    params: any[] = [];//!:HacizParamsInterface
    async handle(task: ITask): Promise<ITask> {
        let data: TopluHacizHazirlaTaskCevap = task.data;
        data.dosya = await app.$uyap.DosyaBul().run(task);
        this.params = data.params.hacizParams;
        let response: TopluHacizGonderTaskResponse = {
            dosyaEsasNo: data.dosya.dosyaNo,
            icraDairesi: data.dosya.birimAdi,
            message: "",
            evraklar: []
        };
        if (data.borclular.length > 0) {
            for (const borclu of data.borclular) {
                try {
                    let hacizController = new HacizController(borclu, data.dosya);
                    for (const param of this.params) {
                        switch (param.className) {
                            case HacizEvrakTypeEnum.ARAC_HACZI: {
                                let egm = borclu.egm as DosyaBorcluEgmCevap;
                                if (egm.sorguSonucu && egm.sorguSonucDVO) {
                                    egm.sorguSonucDVO.aracBilgileri.output.forEach((arac) => {
                                        if (arac.selected == true)
                                            hacizController.aracHacizEkle(arac.hacizSerhi, arac.plakaSifreli, arac.plaka)
                                    })
                                }
                                break;
                            }
                            case HacizEvrakTypeEnum.TASINMAZ_HACZI: {
                                let takbis = borclu.takbis as BorcluTakbisSorgulamaCevap;
                                if (takbis.sorguSonucu && Array.isArray(takbis.sorguSonucDVO)){
                                    takbis.sorguSonucDVO.forEach((tasinmaz) => {
                                        if (tasinmaz.selected == true)
                                            hacizController.tasinmazHacizEkle(tasinmaz);
                                    })
                                }
                                break;
                            }
                            case HacizEvrakTypeEnum.ALACAKLI_OLDUGU_DOSYA: {
                                // TODO: dosyaid değişiyor mu kontrol et
                                let icraDosyaKaydi = borclu.icraDosyaKaydi as BorcluIcraDosyasiCevap;
                                if (icraDosyaKaydi.sorguSonucu && icraDosyaKaydi.sorguSonucDVO) {
                                    icraDosyaKaydi.sorguSonucDVO.forEach((kayitliDosya) => {
                                        hacizController.alacakliOlduguDosyaEkle(kayitliDosya)
                                    })
                                }
                                break;
                            }
                            case HacizEvrakTypeEnum.BANKA_HACZI: {
                                if (borclu.haciz.bankaHesapHacziParams.bankaList.length > 0 &&
                                    borclu.haciz.bankaHesapHacziParams.hesapList.length > 0) {
                                    hacizController.bankaHacizEkle();
                                }
                                break;
                            }
                            case HacizEvrakTypeEnum.POSTA_CEKI_HACZI: {
                                let postaCeki = borclu.postaCeki as BorcluPostaCekiCevap;
                                if (postaCeki.sorguSonucu)
                                    hacizController.postaCekiHacziEkle();
                                break;
                            }
                            case HacizEvrakTypeEnum.TASINIR_HACZI: {
                                hacizController.tasinirHacizEkle(borclu.haciz.tasinirHacziParams.aciklama);
                                break;
                            }
                            case HacizEvrakTypeEnum.TASINIR_HACIZ_TALIMATI: {
                                hacizController.tasinirHacizTalimatiEkle(borclu.haciz.tasinirHacizTalimatiParams);
                            }
                                break;
                            case HacizEvrakTypeEnum.IHBARNAME_89_1: {
                                hacizController.ihbarname89_1Ekle(borclu.haciz.ihbarname89_1Params);
                            }
                                break;
                            case HacizEvrakTypeEnum.IHBARNAME_89_2: {
                                hacizController.ihbarname89_2Ekle(borclu.haciz.ihbarname89_2Params);
                            }
                                break;
                            case HacizEvrakTypeEnum.IHBARNAME_89_3: {
                                hacizController.ihbarname89_3Ekle(borclu.haciz.ihbarname89_3Params);
                            }
                                break;
                            case HacizEvrakTypeEnum.MAAS_HACZI: {
                                let sgkhaciz = borclu.sgkhaciz as BorcluSgkHacizCevap
                                if (sgkhaciz.sorguSonucu) {
                                    //TODO buraya bi bakilacak extra bi kontrol gerekebilir ..
                                    hacizController.maasHacizEkle(borclu.haciz.maasHacziParams)
                                }
                            }
                        }
                    }
                    await hacizController.hacizEvrakHazirla();
                    //hacizController.hacizEvrakIndir();
                    await hacizController.hacizEvrakImzala();
                    let evrakResult = await hacizController.gonder();
                    let borcluKisi= borclu.borcluBilgileri as IKisiTumDVO;
                    response.evraklar.push({
                        message         : evrakResult.message,
                        status          : StatusEnum.success,
                        imzaliBlob      : hacizController.getHacizEvrakImzali(),
                        imzasizBlob     : hacizController.getHacizEvrakImzasiz(),
                        adSoyad         : borcluKisi.adi + " " + borcluKisi.soyadi,
                        tcKimlikNo      : borcluKisi.tcKimlikNo,
                        evrakTutari     : hacizController.evrakTutar.toFixed(2),
                        params          : this.params
                    })
                } catch (e) {
                    console.log("Toplu Haciz Gonder ERROR");
                    console.error(e);
                    let borcluKisi= borclu.borcluBilgileri as IKisiTumDVO;
                    response.evraklar.push({
                        message         : e.message,
                        status          : StatusEnum.error,
                        imzaliBlob      : null,
                        imzasizBlob     : null,
                        adSoyad         : borcluKisi.adi + " " + borcluKisi.soyadi,
                        tcKimlikNo      : borcluKisi.tcKimlikNo,
                        evrakTutari     : 0,
                        params          : this.params
                    });
                }
            }
            let zip = new JSZip();
            for (let evrak of response.evraklar) {
                if (evrak.imzaliBlob) {
                    // @ts-ignore
                    zip.file((evrak.adSoyad + "/" + evrak.imzaliBlob.name), evrak.imzaliBlob);
                }
            }
            let zipBlob = await zip.generateAsync({type: "blob"});
            let formData = new FormData();
            // @ts-ignore
            let name = task.birim_adi + ' ' + task.dosya_esas_no.replaceAll('/', '_') + ' Toplu Haciz Talepleri_' + new Date().getTime() + '.zip';
            formData.append('name', name);
            formData.append('file', zipBlob);
            formData.append('task_id', (task.id as number).toString());
            await app.$http.post('/api/v1/task/file', formData);
        } else {
            response.message = "Dosyada borçlu bulunamadı.";
        }
        let errorExists = response.evraklar.filter(evrak => evrak.status == StatusEnum.error);
        task.status = TaskDurum.BITTI_BASARILI;
        if (errorExists.length == response.evraklar.length)
            task.status = TaskDurum.BITTI_HATALI;
        else if (errorExists.length > 0)
            task.status = TaskDurum.BITTI_EKSIK;
        task.response = response;
        task.description = errorExists.map(x => x.message).join(" , ");
        if (response.message != "")
            task.description = response.message;
        return task;
    }
}