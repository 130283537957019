import BorcluIski from "./BorcluIski";
import DosyaBorclulari from "./DosyaBorclulari";
import AvukatSorgulama from "./AvukatSorgulama";
import Adliyeler from "./Adliyeler";
import AvukatKisiselBilgileriSorgulama from "./AvukatKisiselBilgileriSorgulama";
import AvukatYetkiliKurumlar from "./AvukatYetkiliKurumlar";
import BorcluDib from "./BorcluDib";
import DosyaBorcluMernis from "./DosyaBorcluMernis";
import BorcluGib from "./BorcluGib";
import BorcluGsm from "./BorcluGsm";
import BorcluPostaCeki from "./BorcluPostaCeki";
import BorcluTakbisSorgulama from "./BorcluTakbisSorgulama";
import DosyaBorcluBagkur from "./DosyaBorcluBagkur";
import DosyaBorcluBankaBilgileri from "./DosyaBorcluBankaBilgileri";
import DosyaBorcluEgm from "./DosyaBorcluEgm";
import DosyaBorcluEgmMahrumiyet from "./DosyaBorcluEgmMahrumiyet";
import DosyaBorcluIsyeriSgk from "./DosyaBorcluIsyeriSgk";
import DosyaBorcluSSK from "./DosyaBorcluSSK";
import DosyaDetayBilgileri from "./DosyaDetayBilgileri";
import DosyaEvraklar from "./DosyaEvraklar";
import DosyaHarcMasrafHesabi from "./DosyaHarcMasrafHesabi";
import DosyaHesapBilgileri from "./DosyaHesapBilgileri";
import DosyaOdemeIslemleri from "./DosyaOdemeIslemleri";
import DosyaSafahat from "./DosyaSafahat";
import DosyaSafahatToken from "./DosyaSafahatToken";
import DosyaTarafBilgileri from "./DosyaTarafBilgileri";
import GetDosyaTarafBilgileri from "./GetDosyaTarafBilgileri";
import EvrakIndir from "./EvrakIndir";
import GetIbanDetails from "./GetIbanDetails";
import GetKurumMersisAdresi from "./GetKurumMersisAdresi";
import GonderilebilecekEvrakListesi from "./GonderilebilecekEvrakListesi";
import IcraPesinHarcGetir from "./IcraPesinHarcGetir";
import IcraTakipIlamDosyasiUygunMu from "./IcraTakipIlamDosyasiUygunMu";
import IcraTakipIlamDosyaTuru from "./IcraTakipIlamDosyaTuru";
import IldekiAdliyeler from "./IldekiAdliyeler";
import KisiMernisAdresiVarmi from "./KisiMernisAdresiVarmi";
import KisiSorgula from "./KisiSorgula";
import KurumSorgulama from "./KurumSorgulama";
import MahkemeBirimleri from "./MahkemeBirimleri";
import OdemeYap from "./OdemeYap";
import SgkKamu from "./SgkKamu";
import TahsilatReddiyat from "./TahsilatReddiyat";
import TahsilHarcOranlariniGetir from "./TahsilHarcOranlariniGetir";
import TamamlanmayanDosyalar from "./TamamlanmayanDosyalar";
import MtsTarafTebligatList from "./MtsTarafTebligatList";
import MtsTebligatUcreti from "./MtsTebligatUcreti";
import MtsTebligatGonder from "./MtsTebligatGonder";
import MtsTebligatList from "./MtsTebligatList";
import MtsTebligatSafahatList from "./MtsTebligatSafahatList";
import OdemeIslemiSorgulama from "./OdemeIslemiSorgulama";
import MakbuzIndir from "./MakbuzIndir";
import YargiBirimleri from "./YargiBirimleri";
import AvukatTalepList from "./AvukatTalepList";
import BankaHacziParams from "./BankaHacziParams";
import DosyaKapatmaNedenleri from "./DosyaKapatmaNedenleri";
import PortalAvukatTalepMalTuruList from "./PortalAvukatTalepMalTuruList";
import PortalAvukatTalepTebligatTuruList from "./PortalAvukatTalepTebligatTuruList";
import GetDosyaTarafListesiWithTarafRol from "./GetDosyaTarafListesiWithTarafRol";
import GetMaasHacziParams from "./GetMaasHacziParams";
import GetPortalAvukatTalepAdresTuruList from "./GetPortalAvukatTalepAdresTuruList";
import ParaBirimleri from "./ParaBirimleri";
import AracHacziParams from "./AracHacziParams";
import BorcluSgkHaciz from "./BorcluSgkHaciz";
import BorcluIcraDosyasi from "./BorcluIcraDosyasi";
import TakibiKesinlesenBorcluListesi from "./TakibiKesinlesenBorcluListesi";
import IcraTalepEvrakHazirla from "./IcraTalepEvrakHazirla";
import MtsIcraAcilisAlacakliTaraflar from "./MtsIcraAcilisAlacakliTaraflar";
import MtsIcraTakipBaslat from "./MtsIcraTakipBaslat";
import AvukatIcraTalepEvrakiGonder from "./AvukatIcraTalepEvrakiGonder";
import EvrakGonder from "./EvrakGonder";
import {DosyaBul} from "./DosyaBul";
import AvukatKayitliIbanList from "./AvukatKayitliIbanList";
import GetIbanListesi from "./GetIbanListesi";
import GetAdresList from "./GetAdresList";
import JsonHarcHesapla from "./JsonHarcHesapla";
import TevziAl from "./TevziAl";
import TakipTalebiIndir from "./TakipTalebiIndir";
import MtsTakipTalebiIndir from "./MtsTakipTalebiIndir";
import DosyaAcilisEvrakGonder from "./DosyaAcilisEvrakGonder";
import MtsHarcList from "./MtsHarcList";
import MtsTakipTalebiOlustur from "./MtsTakipTalebiOlustur";
import MtsDavaAcilis from "./MtsDavaAcilis";
import VakifbankSmsOnay from "./VakifbankSmsOnay";
import VakifbankHesapListesi from "./VakifbankHesapListesi";
import SetCookie from "./SetCookie";
import DosyaBorcluMersis from "./DosyaBorcluMersis";
import IcraTakipMahiyetleri from "./IcraTakipMahiyetleri";
import ChangePlayerStatus from "@/plugins/uyap-plugin/uyap/ChangePlayerStatus";
import IldekiIdariBirimler from "@/plugins/uyap-plugin/uyap/IldekiIdariBirimler";
import AdliyedekiTumMahkemeler from "@/plugins/uyap-plugin/uyap/AdliyedekiTumMahkemeler";
import AvukatIcraDosyalari from "@/plugins/uyap-plugin/uyap/AvukatIcraDosyalari";
import KisiSorgulaWithAdSoyad from "@/plugins/uyap-plugin/uyap/KisiSorgulaWithAdSoyad";
import MtsAlacakliIletisimBilgisi from "@/plugins/uyap-plugin/uyap/MtsAlacakliIletisimBilgisi";
import {TamamlanmayanDosyaBul} from "@/plugins/uyap-plugin/uyap/TamamlanmayanDosyaBul";

class UyapClient {
    // ok
    Adliyeler(): Adliyeler {
        return new Adliyeler();
    }

    // ok
    AvukatIcraDosyalari(): AvukatIcraDosyalari {
        return new AvukatIcraDosyalari();
    }

    // ok
    AracHacziParams(): AracHacziParams {
        return new AracHacziParams();
    }

    // TODO: Bakılacak
    DosyaBul(): DosyaBul {
        return new DosyaBul();
    }

    TamamlanmayanDosyaBul(): TamamlanmayanDosyaBul {
        return new TamamlanmayanDosyaBul();
    }

    SetCookie(): SetCookie {
        return new SetCookie();
    }

    ChangePlayerStatus(): ChangePlayerStatus {
        return new ChangePlayerStatus();
    }

    // ok
    AvukatKisiselBilgileriSorgulama(): AvukatKisiselBilgileriSorgulama {
        return new AvukatKisiselBilgileriSorgulama();
    }

    // ok
    AvukatIcraTalepEvrakiGonder(): AvukatIcraTalepEvrakiGonder {
        return new AvukatIcraTalepEvrakiGonder();
    }

    // ok
    AvukatSorgulama(): AvukatSorgulama {
        return new AvukatSorgulama();
    }

    // ok
    AvukatTalepList(): AvukatTalepList {
        return new AvukatTalepList();
    }

    // ok
    AvukatYetkiliKurumlar(): AvukatYetkiliKurumlar {
        return new AvukatYetkiliKurumlar();
    }

    // ok
    BankaHacziParams(): BankaHacziParams {
        return new BankaHacziParams();
    }

    // ok
    IcraTakipMahiyetleri(): IcraTakipMahiyetleri {
        return new IcraTakipMahiyetleri();
    }

    // ok
    BorcluDib(): BorcluDib {
        return new BorcluDib();
    }

    // ok
    JsonHarcHesapla(): JsonHarcHesapla {
        return new JsonHarcHesapla();
    }

    // ok
    TevziAl(): TevziAl {
        return new TevziAl();
    }

    // ok
    TakipTalebiIndir(dosyaId: string): TakipTalebiIndir {
        return new TakipTalebiIndir(dosyaId);
    }

    // ok
    MtsTakipTalebiIndir(dosyaId: string): MtsTakipTalebiIndir {
        return new MtsTakipTalebiIndir(dosyaId);
    }

    // ok
    BorcluGib(): BorcluGib {
        return new BorcluGib();
    }

    // TODO: uyapda bozuk
    BorcluGsm(): BorcluGsm {
        return new BorcluGsm();
    }

    // ok
    BorcluIcraDosyasi(): BorcluIcraDosyasi {
        return new BorcluIcraDosyasi();
    }

    // ok
    BorcluIski(): BorcluIski {
        return new BorcluIski();
    }

    // ok
    BorcluPostaCeki(): BorcluPostaCeki {
        return new BorcluPostaCeki();
    }

    // ok
    BorcluSgkHaciz(): BorcluSgkHaciz {
        return new BorcluSgkHaciz();
    }

    // ok
    DosyaBorclulari(): DosyaBorclulari {
        return new DosyaBorclulari();
    }

    // ok
    DosyaBorcluBankaBilgileri(): DosyaBorcluBankaBilgileri {
        return new DosyaBorcluBankaBilgileri();
    }

    // ok
    DosyaBorcluMernis(): DosyaBorcluMernis {
        return new DosyaBorcluMernis();
    }

    // ok
    DosyaBorcluMersis(): DosyaBorcluMersis {
        return new DosyaBorcluMersis();
    }

    // ok
    BorcluTakbisSorgulama(): BorcluTakbisSorgulama {
        return new BorcluTakbisSorgulama();
    }

    // ok
    DosyaBorcluBagkur(): DosyaBorcluBagkur {
        return new DosyaBorcluBagkur();
    }

    // ok
    DosyaBorcluEgm(): DosyaBorcluEgm {
        return new DosyaBorcluEgm();
    }

    // ok
    DosyaBorcluEgmMahrumiyet(): DosyaBorcluEgmMahrumiyet {
        return new DosyaBorcluEgmMahrumiyet();
    }

    // ok
    DosyaBorcluIsyeriSgk(): DosyaBorcluIsyeriSgk {
        return new DosyaBorcluIsyeriSgk();
    }

    // ok
    DosyaBorcluSSK(): DosyaBorcluSSK {
        return new DosyaBorcluSSK();
    }

    // ok
    DosyaDetayBilgileri(): DosyaDetayBilgileri {
        return new DosyaDetayBilgileri();
    }

    // ok
    DosyaEvraklar(): DosyaEvraklar {
        return new DosyaEvraklar();
    }

    // ok
    DosyaHarcMasrafHesabi(): DosyaHarcMasrafHesabi {
        return new DosyaHarcMasrafHesabi();
    }

    // ok
    DosyaHesapBilgileri(): DosyaHesapBilgileri {
        return new DosyaHesapBilgileri();
    }

    // ok
    OdemeIslemiSorgulama(): OdemeIslemiSorgulama {
        return new OdemeIslemiSorgulama();
    }

    // ok
    DosyaKapatmaNedenleri(): DosyaKapatmaNedenleri {
        return new DosyaKapatmaNedenleri();
    }

    // ok
    DosyaOdemeIslemleri(): DosyaOdemeIslemleri {
        return new DosyaOdemeIslemleri();
    }

    // kaldırılmış
    DosyaSafahatToken(): DosyaSafahatToken {
        return new DosyaSafahatToken();
    }

    // ok
    DosyaSafahat(): DosyaSafahat {
        return new DosyaSafahat();
    }

    // gerek olmayabilir
    VakifbankHesapListesi(): VakifbankHesapListesi {
        return new VakifbankHesapListesi();
    }

    // gerek olmayabilir
    VakifbankSmsOnay(): VakifbankSmsOnay {
        return new VakifbankSmsOnay();
    }

    // ok
    AvukatKayitliIbanList(): AvukatKayitliIbanList {
        return new AvukatKayitliIbanList();
    }

    // ok
    GetIbanListesi(): GetIbanListesi {
        return new GetIbanListesi();
    }

    // ok
    DosyaTarafBilgileri(): DosyaTarafBilgileri {
        return new DosyaTarafBilgileri();
    }

    // ok
    GetDosyaTarafBilgileri(): GetDosyaTarafBilgileri {
        return new GetDosyaTarafBilgileri();
    }

    // kullanılan yerlere bakılacak -dosya aracı -3. şahıs
    GetDosyaTarafListesiWithTarafRol(): GetDosyaTarafListesiWithTarafRol {
        return new GetDosyaTarafListesiWithTarafRol();
    }

    // har alınacak
    EvrakGonder(): EvrakGonder {
        return new EvrakGonder();
    }

    // ok
    DosyaAcilisEvrakGonder(): DosyaAcilisEvrakGonder {
        return new DosyaAcilisEvrakGonder();
    }

    // kullanılan yerler incelencek
    EvrakIndir(): EvrakIndir {
        return new EvrakIndir();
    }

    // ok
    MakbuzIndir(): MakbuzIndir {
        return new MakbuzIndir();
    }

    // ok
    GetIbanDetails(): GetIbanDetails {
        return new GetIbanDetails();
    }

    // ok
    GetAdresList(): GetAdresList {
        return new GetAdresList();
    }

    // ok
    GetKurumMersisAdresi(): GetKurumMersisAdresi {
        return new GetKurumMersisAdresi();
    }

    // ok
    GetMaasHacziParams(): GetMaasHacziParams {
        return new GetMaasHacziParams();
    }

    // ok
    GetPortalAvukatTalepAdresTuruList(): GetPortalAvukatTalepAdresTuruList {
        return new GetPortalAvukatTalepAdresTuruList();
    }

    // ok
    GonderilebilecekEvrakListesi(): GonderilebilecekEvrakListesi {
        return new GonderilebilecekEvrakListesi();
    }

    // ok
    IcraPesinHarcGetir(): IcraPesinHarcGetir {
        return new IcraPesinHarcGetir();
    }

    // ok
    IcraTakipIlamDosyasiUygunMu(): IcraTakipIlamDosyasiUygunMu {
        return new IcraTakipIlamDosyasiUygunMu();
    }

    // ok
    IcraTakipIlamDosyaTuru(): IcraTakipIlamDosyaTuru {
        return new IcraTakipIlamDosyaTuru();
    }

    // har lazım
    IldekiIdariBirimler(): IldekiIdariBirimler {
        return new IldekiIdariBirimler();
    }

    // ok
    IldekiAdliyeler(): IldekiAdliyeler {
        return new IldekiAdliyeler();
    }

    // ok
    AdliyedekiTumMahkemeler(): AdliyedekiTumMahkemeler {
        return new AdliyedekiTumMahkemeler();
    }

    // ok
    IcraTalepEvrakHazirla(): IcraTalepEvrakHazirla {
        return new IcraTalepEvrakHazirla();
    }

    // ok
    KisiMernisAdresiVarmi(): KisiMernisAdresiVarmi {
        return new KisiMernisAdresiVarmi();
    }

    // ok
    KisiSorgula(): KisiSorgula {
        return new KisiSorgula();
    }

    // ok
    KisiSorgulaWithAdSoyad(): KisiSorgulaWithAdSoyad {
        return new KisiSorgulaWithAdSoyad();
    }

    // ok
    MtsAlacakliIletisimBilgisi(): MtsAlacakliIletisimBilgisi {
        return new MtsAlacakliIletisimBilgisi();
    }

    // ok
    KurumSorgulama(): KurumSorgulama {
        return new KurumSorgulama();
    }

    MahkemeBirimleri(): MahkemeBirimleri {
        return new MahkemeBirimleri();
    }

    // har lazım
    MtsTarafTebligatList(): MtsTarafTebligatList {
        return new MtsTarafTebligatList();
    }

    // har lazım
    MtsTebligatList(): MtsTebligatList {
        return new MtsTebligatList();
    }

    // har lazım
    MtsTebligatSafahatList(): MtsTebligatSafahatList {
        return new MtsTebligatSafahatList();
    }

    // har lazım
    MtsIcraAcilisAlacakliTaraflar(): MtsIcraAcilisAlacakliTaraflar {
        return new MtsIcraAcilisAlacakliTaraflar();
    }

    // har lazım
    MtsIcraTakipBaslat(): MtsIcraTakipBaslat {
        return new MtsIcraTakipBaslat();
    }

    // ok
    MtsHarcList(): MtsHarcList {
        return new MtsHarcList();
    }

    // ok
    MtsTakipTalebiOlustur(): MtsTakipTalebiOlustur {
        return new MtsTakipTalebiOlustur();
    }

    // har lazım
    MtsDavaAcilis(): MtsDavaAcilis {
        return new MtsDavaAcilis();
    }

    // ok
    OdemeYap(): OdemeYap {
        return new OdemeYap();
    }

    // ok
    ParaBirimleri(): ParaBirimleri {
        return new ParaBirimleri();
    }

    // ok
    PortalAvukatTalepMalTuruList(): PortalAvukatTalepMalTuruList {
        return new PortalAvukatTalepMalTuruList();
    }

    // ok
    PortalAvukatTalepTebligatTuruList(): PortalAvukatTalepTebligatTuruList {
        return new PortalAvukatTalepTebligatTuruList();
    }

    // ok
    SgkKamu(): SgkKamu {
        return new SgkKamu();
    }

    // ok
    TahsilatReddiyat(): TahsilatReddiyat {
        return new TahsilatReddiyat();
    }

    // ok
    TahsilHarcOranlariniGetir(): TahsilHarcOranlariniGetir {
        return new TahsilHarcOranlariniGetir();
    }

    TakibiKesinlesenBorcluListesi(): TakibiKesinlesenBorcluListesi {
        return new TakibiKesinlesenBorcluListesi();
    }

    // ok
    TamamlanmayanDosyalar(): TamamlanmayanDosyalar {
        return new TamamlanmayanDosyalar();
    }

    // ok
    MtsTebligatUcreti(): MtsTebligatUcreti {
        return new MtsTebligatUcreti();
    }

    // ok
    MtsTebligatGonder(): MtsTebligatGonder {
        return new MtsTebligatGonder();
    }

    YargiBirimleri(): YargiBirimleri {
        return new YargiBirimleri();
    }
}

export default UyapClient;